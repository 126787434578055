/**
 * Created J/20/06/2013
 * Updated L/02/10/2023
 *
 * Copyright 2008-2025 | Fabrice Creuzot (luigifab) <code~luigifab~fr>
 * https://github.com/luigifab/apijs
 *
 * This program is free software, you can redistribute it or modify
 * it under the terms of the GNU General Public License (GPL) as published
 * by the free software foundation, either version 2 of the license, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but without any warranty, without even the implied warranty of
 * merchantability or fitness for a particular purpose. See the
 * GNU General Public License (GPL) for more details.
 */

/* &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&& conteneurs &&& */
div.gallery, div.album { font-size:0.85em; }
div.gallery img, div.album img { display:block; margin:auto; overflow:hidden; color:transparent; }
div.gallery a:before, div.album a:before, div.gallery a:after, div.album a:after { display:none; }

div.gallery { display:inline-block; }
div.gallery a { display:inline-block; text-decoration:none; }
div.gallery ul { margin:0; padding:0; list-style:none; }
div.gallery li { display:inline-block; margin:0; padding:0; vertical-align:middle; }
div.gallery li a { display:block; margin:0.2em; }
div.gallery li a.current { border-color:#808080; }

div.album { text-align:center; }
div.album dl { display:inline-block; margin:0.5em 0.4em; vertical-align:top; }
div.album dt a { display:block; text-decoration:none; }
div.album dd { margin:0.4em 0 0; }

#apijsDialog { display:none; }